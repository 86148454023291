import React from "react";
import { cleanFundo, SidebarFilterFunction } from "../..";
import { getidentifier } from "../../DataHandler/DataHandler.jsx";
import { Loading } from "../../Loading/LoadingScreen.jsx";

var list, modo;
var separador = 0;

export function setList(value) {
    list = value
}

export function setSeparador(value) {
    separador = value
}

export function getSeparador() {
    return separador
}

export function setModo(value) {
    modo = value
}

export default function TypeFilter() {
    var inp = document.getElementById("typeFilter")
    var arr = list[separador]

    function addActive(x) {
        if (!x) return false;
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        document.querySelectorAll(".toggleSessionsEnable i").forEach(element => { element.style.opacity = '1' })
        document.querySelectorAll(".sliderButton").forEach(element => { element.style.opacity = '1' })
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    document.addEventListener("click", function (e) {
        const id = e.target.id
        if (id !== "typeFilter") {
            closeAllLists(e.target);
        }
    });

    function KeyDown(e) {
        var x = document.getElementById(e.target.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            currentFocus++;
            addActive(x);
        } else if (e.keyCode == 38) {
            currentFocus--;
            addActive(x);
        } else if (e.keyCode == 13) {
            e.preventDefault();
            if (currentFocus > -1) {
                if (x) x[currentFocus].click();
            }
        }
    }
    if (getidentifier() !== 'nomenoFilter') {
        var currentFocus;
        String.prototype.contains = function contains(typedValue) {
            typedValue = typedValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
            const roadName = this.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
            const vetor = roadName.split(typedValue)
            return (vetor.length > 1) ? true : false
        }


        const openList = (e) => {
            var a, b, i, val = e.target.value;
            closeAllLists();
            currentFocus = -1;
            a = document.createElement("DIV");
            a.setAttribute("id", e.target.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            e.target.parentNode.appendChild(a);
            document.querySelectorAll(".toggleSessionsEnable i").forEach(element => { element.style.opacity = '0' })
            document.querySelectorAll(".sliderButton").forEach(element => { element.style.opacity = '0' })
            let counter = 0
            for (i = 0; i < arr.length; i++) {
                try {
                    if (arr[i].contains(val)) {
                        if (counter === 100) {
                            break
                        }
                        b = document.createElement("DIV");
                        const ruaVetor = arr[i].toUpperCase().split(val.toUpperCase())
                        for (let i = 0; i < ruaVetor.length; i++) {
                            b.innerHTML += ruaVetor[i]
                            if (i < (ruaVetor.length - 1)) {
                                b.innerHTML += "<strong>" + val.toUpperCase() + "</strong>"
                            }
                        }
                        b.innerHTML += "<input type='hidden' value='" + arr[i].toUpperCase() + "'>";
                        b.addEventListener("click", function (e) {
                            setTimeout(() => {
                                try {
                                    Loading("iniciar")
                                    setTimeout(() => {
                                        cleanFundo()
                                        closeAllLists();
                                        inp.value = e.target.getElementsByTagName("input")[0].value;
                                        SidebarFilterFunction(inp.value)
                                    }, 100);
                                } catch (error) { console.log(error) }
                            }, 100);
                        });
                        a.appendChild(b);
                        counter++
                    }
                } catch (error) { console.log(error) }
            }
        }

        return (
            <div className="completeLabel">
                <form method="get">
                    <input
                        onKeyDown={(e) => KeyDown(e)} onChange={(e) => openList(e)}
                        onClick={(e) => openList(e)}
                        autoComplete="off" placeholder={`Pesquisa por ${modo}`}
                        className="formInput" id="typeFilter" />
                    <span className="fas fa-search"></span>
                </form>
            </div>
        )
    } else {
        return (
            <div></div>
        )
    }

}