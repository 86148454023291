import React, { useEffect, useState } from "react";
import { Calendar } from 'react-calendar'
import { changeDateMap, changeMapColors, getDadosfato, getRequest } from "../../index";
import { getModeMap, getSlideDate, setData } from "../../DataHandler/DataHandler.jsx";
import { Loading } from "../../Loading/LoadingScreen.jsx";

export function Calendario() {
    let minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    let maxDate = new Date(new Date().setMonth(new Date().getMonth() - 1))
    const mes = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
    const [value, onChange] = useState(maxDate);

    useEffect(() => {
        if (getSlideDate() !== undefined) { onChange(getSlideDate()) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSlideDate()])

    const formatLabel = (date) => {
        return `${mes[date.getUTCMonth()] + '\n' + date.getUTCFullYear()}`
    }

    const monthClick = (value) => {
        Loading("iniciar")
        function join(t, a, s) {
            function format(m) {
                let f = new Intl.DateTimeFormat('en', m);
                return f.format(t);
            }
            return a.map(format).join(s);
        }
        setData(`${mes[value.getUTCMonth()] + '/' + value.getUTCFullYear()}`)
        let date = [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' },];
        let formatedDate = join(value, date, '-');

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const formattedDate = `${year}/${month}`;
        document.getElementById("calendarModule").classList.remove("active")
        setTimeout(() => { document.querySelectorAll(".toggleSessionsEnable i").forEach(element => { element.style.opacity = '1' }) }, 200);
        setTimeout(() => { document.querySelectorAll(".sliderButton").forEach(element => { element.style.opacity = '1' }) }, 200);
        document.getElementById("TimeControl").classList.remove("active")
        if (getModeMap() === "rugosidade ia") {
            getRequest(`${getDadosfato()}${formatedDate}.json`).then((response) => {
                changeMapColors(response)
            })
            changeDateMap(formattedDate)
        } else if (getModeMap() === "ia") {
            changeDateMap(formattedDate)
        } else if (getModeMap() === "rugosidade") {
            getRequest(`${getDadosfato()}${formatedDate}.json`).then((response) => {
                changeMapColors(response)
            })
        }
    }

    return (
        <div id="calendarModule" className="calendarHub">
            <div className="calendarModule">
                <Calendar
                    value={value} locale={'pt-BR'} maxDate={maxDate} onChange={onChange}
                    minDate={minDate} minDetail={'decade'} maxDetail={'year'}
                    navigationLabel={({ date }) => formatLabel(date)}
                    onClickMonth={(value) => monthClick(value)}
                />
            </div>
        </div>
    )
}