var mapFilters, identifier, identifierId, selected,
    lat, lng, zoomMap, dateFile,
    rua, selectedClasseViaria, data, slideDate,
    data1, data2, data3, data4, data5, data6, data7, data8, data9,
    data10, data11, data12, modeMap, globalFilters;
var opacidade = false
var orientation = null
var menuToggle = true
// SETTERS

export function setMapFilters(value) { mapFilters = value }

export function setidentifier(value) { identifier = value }

export function setSelected(value) { selected = value }

export function setIdentifierId(value) { identifierId = value }

export function setOpacidade(value) { opacidade = value }

export function setLat(value) { lat = value }

export function setLng(value) { lng = value }

export function setZoomMap(value) { zoomMap = value }

export function setRua(value) { rua = value }

export function setSelectedClasseViaria(value) { selectedClasseViaria = value }

export function setData(value) { data = value }

export function set1(value) { data1 = value }

export function set2(value) { data2 = value }

export function set3(value) { data3 = value }

export function set4(value) { data4 = value }

export function set5(value) { data5 = value }

export function set6(value) { data6 = value }

export function set7(value) { data7 = value }

export function set8(value) { data8 = value }

export function set9(value) { data9 = value }

export function set10(value) { data10 = value }

export function set11(value) { data11 = value }

export function set12(value) { data12 = value }

export function setSlideDate(value) { slideDate = value }

export function setMenuToggle(value) { menuToggle = value }

export function setOrientation(value) { orientation = value }

export function setModeMap(value) {
    const setter = value.join(" ");

    if (setter.includes("rugosidade") && setter.includes("ia")) {
      modeMap = "rugosidade ia";
    } else if (setter.includes("ia")) {
      modeMap = "ia";
    } else if (setter.includes("rugosidade")) {
      modeMap = "rugosidade";
    }
}

export function setGlobalFilters(value) { globalFilters = value }

export function setDateFile(value) { dateFile = value }

// GETTERS

export function getMapFilters() { return mapFilters }

export function getidentifier() { return identifier }

export function getSelected() { return selected }

export function getIdentifierId() { return identifierId }

export function getOpacidade() { return opacidade }

export function getLat() { return lat }

export function getLng() { return lng }

export function getZoomMap() { return zoomMap }

export function getRua() { return rua }

export function getSelectedClasseViaria() { return selectedClasseViaria }

export function getData() { return data }

export function get1() { return data1 }

export function get2() { return data2 }

export function get3() { return data3 }

export function get4() { return data4 }

export function get5() { return data5 }

export function get6() { return data6 }

export function get7() { return data7 }

export function get8() { return data8 }

export function get9() { return data9 }

export function get10() { return data10 }

export function get11() { return data11 }

export function get12() { return data12 }

export function getSlideDate() { return slideDate }

export function getMenuToggle() { return menuToggle }

export function getOrientation() { return orientation }

export function getModeMap() { return modeMap }

export function getGlobalFilters() { return globalFilters }

export function getDateFile() { return dateFile }
